import { useApolloClient, useQuery } from '@apollo/client';
import { useMemo, useRef } from 'react';
import { CRM_OBJECT_TABLE_SEARCH_QUERY } from '../types';
import { useClearSyncedObjectMutations } from '../../localMutations/useClearSyncedObjectMutations';
const IDLE_CALLBACK_FALLBACK_TIMEOUT = 2000;
const executeAtLowerPriority = callback => {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(callback);
  } else {
    setTimeout(callback, IDLE_CALLBACK_FALLBACK_TIMEOUT);
  }
};
export const useLegacyFastCrmObjectsSearch = ({
  crmSearchQueryObject,
  pollInterval,
  skip,
  initialFetchPolicy = 'cache-first'
}) => {
  const lazilyWriteToCache = useRef(initialFetchPolicy === 'no-cache');
  const client = useApolloClient();
  const clearSyncedObjectMutations = useClearSyncedObjectMutations();
  const {
    loading,
    data,
    error,
    refetch,
    startPolling,
    stopPolling,
    networkStatus
  } = useQuery(CRM_OBJECT_TABLE_SEARCH_QUERY, {
    context: {
      noBatch: true
    },
    fetchPolicy: initialFetchPolicy,
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: crmSearchQueryObject,
    pollInterval,
    skip,
    onCompleted: response => {
      /**
       * Whenever we get a response, we can clear the "synced" mutations for this object type
       * This is because we've just fetched the data from the server, so we know it's up to date.
       * We avoid clearing mutations that we consider "async" (e.g. delete) since server values
       * may not be consistent with local values due to async operations like search indexing and
       * async bulk operations.
       */
      clearSyncedObjectMutations(crmSearchQueryObject.objectTypeId);
      if (!lazilyWriteToCache.current) {
        return;
      }
      lazilyWriteToCache.current = false;
      executeAtLowerPriority(() => {
        client.writeQuery({
          query: CRM_OBJECT_TABLE_SEARCH_QUERY,
          data: response,
          variables: crmSearchQueryObject
        });
      });
    }
  });
  return useMemo(() => ({
    loading,
    data,
    error,
    refetch,
    startPolling,
    stopPolling,
    networkStatus
  }), [loading, data, error, refetch, startPolling, stopPolling, networkStatus]);
};