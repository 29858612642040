import http from 'hub-http/clients/apiClient';
import { useEffect, useMemo } from 'react';
import { createNewFastCrmObjectsSearchArgs, generateNewFastCrmObjectsSearchQuickFetchName } from './NewFastCrmObjectsSearchArgs';
import { registerQuery, useQuery as useDFCQuery } from 'data-fetching-client';
import { useFetchUserInfo } from '../useFetchUserInfo';
import { Metrics } from '../../Metrics';
import { useClearSyncedObjectMutations } from '../../localMutations/useClearSyncedObjectMutations';
import quickFetch from 'quick-fetch';
import Raven from 'raven-js';
class FetcherError extends Error {
  constructor({
    message,
    status,
    correlationId
  }) {
    super(message);
    this.status = status;
    this.message = message;
    this.correlationId = correlationId;
  }
}
/** Upon being called this will make an http call to crm search, this is used for scenarios where there is not a quick fetch request already made or it has failed
 */
const makePostRequest = ({
  objectTypeId,
  filterGroups,
  sorts,
  count,
  offset,
  query,
  properties,
  userId
}) => {
  const newArgs = createNewFastCrmObjectsSearchArgs({
    objectTypeId,
    filterGroups,
    sorts,
    count,
    offset,
    query,
    properties,
    userId
  });
  return http.post('sales-views/v2/crm-search/with-placeholders', {
    data: newArgs
  });
};

/**
 * checks to see if there is a quick fetch request that has already been made with the given "args", and if so returns it.
 */
const getQuickFetchRequest = args => {
  const requestName = generateNewFastCrmObjectsSearchQuickFetchName(args);
  const earlyMetaRequest = quickFetch.getRequestStateByName(requestName);
  if (!earlyMetaRequest) {
    console.warn('SalesViewsV2CrmSearchWithPlaceholders quick fetch was not found - please add it! We will gracefully fall back to making the request on our own');
    return null;
  }
  return new Promise((resolve, reject) => {
    earlyMetaRequest.whenFinished(result => {
      resolve(result);
      quickFetch.removeEarlyRequest(requestName);
    });
    earlyMetaRequest.onError(() => {
      // retry the request
      makePostRequest(args).then(resolve, reject).catch(e => {
        const err = new Error('crm-object-search-query-utilities quick fetch as well as real fetch errored');
        Raven.captureException(err, {
          fingerprint: e.message
        });
      });
      quickFetch.removeEarlyRequest(requestName);
    });
  });
};
const fetcher = args => {
  if (!args.userId) {
    throw new Error('userId was undefined');
  }
  const requestPromise = getQuickFetchRequest(args) || makePostRequest(args);
  return requestPromise.then(response => {
    return {
      crmObjectsSearch: Object.assign({
        validationErrors: []
      }, response, {
        results: response.results.map(result => {
          return {
            id: result.id,
            objectId: result.objectId,
            __typename: 'BasicCrmObject',
            userPermissions: {
              currentUserCanEdit: result.currentUserPermissions.canEdit,
              currentUserCanDelete: result.currentUserPermissions.canDelete,
              __typename: 'ObjectPermissions'
            },
            //this is not performant (mapping over every property for every object
            //this needs to be adjusted once/if we remove the legacy version.
            //if this "new" version is not as performant as what we would expect, we can investigate allowing the different hooks in this repo accept two formats (new and old)
            //to prevent us from needing to do an objectmap->array-of-names-and-values-objectmap style conversion
            properties: Object.entries(result.properties).map(([propertyName, propertyValueObj]) => ({
              id: `${propertyName}-${propertyValueObj === null || propertyValueObj === void 0 ? void 0 : propertyValueObj.value}`,
              name: propertyName,
              value: (propertyValueObj === null || propertyValueObj === void 0 ? void 0 : propertyValueObj.value) || null,
              __typename: 'PropertyValue'
            }))
          };
        })
      })
    };
  }).catch(e => {
    if (e.responseJSON) {
      throw new FetcherError({
        message: e.responseJSON.message,
        correlationId: e.responseJSON.correlationId,
        status: e.status
      });
    }
    throw e;
  });
};
const REST_CRM_OBJECTS_SEARCH_QUERY_NAME = 'restCrmObjectsSearchQuery';
export const REST_CRM_OBJECTS_SEARCH_QUERY = registerQuery({
  fieldName: REST_CRM_OBJECTS_SEARCH_QUERY_NAME,
  args: ['objectTypeId', 'filterGroups', 'sorts', 'count', 'offset', 'query', 'properties', 'userId'],
  fetcher
});
export const useNewFastCrmObjectsSearch = ({
  crmSearchQueryObject,
  pollInterval,
  skip // initialFetchPolicy = 'cache-first',
}) => {
  const {
    data: userInfoData,
    loading: userInfoLoading,
    error: userInfoError
  } = useFetchUserInfo();
  useEffect(() => {
    if (userInfoLoading) {
      Metrics.counter('user-info-loading').increment();
      //if you are seeing this metric, it means that crm-object-table is not loading as fast as it could be.
      //please fire off a hub-http/userInfo request early in page load
    }
  }, [userInfoLoading]);
  const userId = userInfoData === null || userInfoData === void 0 ? void 0 : userInfoData.user.user_id;
  const internalSkip = Boolean(skip || userInfoLoading || userInfoError);
  const queryArguments = useMemo(() => Object.assign({}, crmSearchQueryObject, {
    userId
  }), [crmSearchQueryObject, userId]);
  const clearSyncedObjectMutations = useClearSyncedObjectMutations();
  const {
    data,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling,
    networkStatus
  } = useDFCQuery(REST_CRM_OBJECTS_SEARCH_QUERY, {
    pollInterval,
    variables: queryArguments,
    skip: internalSkip,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      /**
       * Whenever we get a response, we can clear the "synced" mutations for this object type
       * This is because we've just fetched the data from the server, so we know it's up to date.
       * We avoid clearing mutations that we consider "async" (e.g. delete) since server values
       * may not be consistent with local values due to async operations like search indexing and
       * async bulk operations.
       */
      clearSyncedObjectMutations(crmSearchQueryObject.objectTypeId);
    }
  });
  return useMemo(() => ({
    data: data === null || data === void 0 ? void 0 : data[REST_CRM_OBJECTS_SEARCH_QUERY_NAME],
    loading,
    error,
    refetch,
    startPolling,
    stopPolling,
    networkStatus
  }), [data, error, loading, networkStatus, refetch, startPolling, stopPolling]);
};