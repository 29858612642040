import PortalIdParser from 'PortalIdParser';
const REQUIRED_PROPERTIES_FOR_ALL_OBJECT_TYPES = ['hs_object_id'];
const REQUIRED_PROPERTIES_BY_OBJECT_TYPE = {
  '0-1': ['firstname', 'lastname', 'email', 'hs_additional_emails', 'hs_additional_domains',
  // both of these analytics keys are used to display each other
  // see: https://git.hubteam.com/HubSpot/CRM/pull/24039/files#r1167989
  'hs_analytics_source', 'hs_analytics_source_data_1', 'hs_latest_source', 'hs_latest_source_data_1', 'hs_object_source_id',
  // I think this is a magic property?
  'objectType', 'hubspot_owner_id', 'hs_all_owner_ids', 'hubspot_team_id', 'hs_all_team_ids', 'hs_all_accessible_team_ids', 'createdate', 'hs_lastactivitydate', 'notes_last_created', 'notes_last_updated',
  // https://git.hubteam.com/HubSpot/customer-data-properties/blob/master/customer-data-properties/static-1.39527/js/v2/components/FormattedPropertyV2.tsx#L420
  'hs_marketable_reason_type'],
  '0-2': ['name', 'domain',
  // domain often requires hs_additional_domains to render all domains
  'hs_additional_domains', 'phone', 'website', 'hubspot_owner_id', 'hs_all_owner_ids', 'hubspot_team_id', 'hs_all_team_ids', 'hs_all_accessible_team_ids', 'hs_annual_revenue_currency_code', 'createdate', 'hs_lastactivitydate', 'notes_last_created', 'notes_last_updated',
  // both of these analytics keys are used to display each other
  // see: https://git.hubteam.com/HubSpot/CRM/pull/24039/files#r1167989
  'hs_analytics_source', 'hs_analytics_source_data_1', 'hs_latest_source', 'hs_latest_source_data_1'],
  '0-3': [
  // Adapted from DealBoardSearchAPI's list of included properties:
  'dealname', 'amount', 'amount_in_home_currency', 'description', 'pipeline', 'closedate', 'createdate', 'deal_currency_code', 'dealtype', 'dealstage', 'relatesTo', 'hubspot_owner_id', 'hs_all_owner_ids', 'hs_priority', 'hubspot_team_id', 'hs_all_team_ids', 'hs_all_accessible_team_ids', 'hs_lastactivitydate', 'hs_latest_approval_status', 'notes_last_created', 'notes_last_updated', 'hs_num_associated_deal_splits', 'notes_next_activity_date',
  // both of these analytics keys are used to display each other
  // see: https://git.hubteam.com/HubSpot/CRM/pull/24039/files#r1167989
  'hs_analytics_source', 'hs_analytics_source_data_1', 'hs_latest_source', 'hs_latest_source_data_1', 'hs_is_closed', 'hs_projected_amount', 'hs_projected_amount_in_home_currency'],
  '0-5': [
  // Adapted from TicketBoardSearchAPI's list of included properties:
  'closed_date', 'content', 'createdate', 'hs_ticket_priority', 'hs_pipeline', 'hs_pipeline_stage', 'hubspot_owner_id', 'hs_all_owner_ids', 'hubspot_team_id', 'hs_all_team_ids', 'hs_all_accessible_team_ids', 'subject', 'hs_lastactivitydate', 'hs_nextactivitydate', 'notes_last_created', 'notes_next_activity_date', 'notes_last_updated'
  // NOTE: analytics properties are not set on tickets, only contacts/companies/deals
  // See https://product.hubteam.com/docs/crm-properties/values/analytics_sources.html
  ],
  '0-7': ['hs_recurring_billing_period', 'recurringbillingfrequency'],
  '0-27': ['hs_task_status', 'hs_task_subject', 'hs_timestamp'],
  '0-40': ['hs_created_by_user_id', 'hs_createdate', 'hs_import_id', 'hs_import_system', 'hs_import_state', 'hs_counter_total_rows'],
  '0-53': ['hs_currency', 'hs_due_date', 'hs_invoice_source', 'hs_invoice_status', 'hs_payment_date'],
  '0-69': ['hs_currency_code', 'hs_invoice_creation'],
  '0-101': ['hs_currency_code'],
  '0-123': ['hs_currency_code']
};
const PORTAL_SPECIFIC_REQUIRED_PROPERTIES = {
  53: {
    '2-5945472': ['currency_53']
  },
  99535353: {
    '2-5868076': ['currency_53']
  }
};

/**
 * "Required properties" is business logic we use on the frontend to account for scenarios like the following:
 *
 * I want to search for deals, and render them in a table, and have deal amount as a column. The deal
 * amount property is an integer like "100", but does not contain a currency code. If I were to try and
 * render this to the user, they would see a currency-less deal amount. We need to also fetch deal_currency_code.
 *
 * Frontend apps that fetch a single object, like the record page, can fetch all properties for the single object, they never
 * have to worry about a property missing. The perf hit of all properties for a single object is negligable.
 *
 * For the index page, if we were to request all properties for all objects, the response from crm search is slow and large.
 * The median index page load speed is 2.01s as of Apr 2024. Fetching all properties for 100 deals takes over 2s just on its own.
 *
 * This leads to a weird situation. When hitting search, we cant fetch all properties because its not performant. But we also
 * need to "know" that we need some properties when fetching others.
 * As of right now, we have a hardcoded map on the frontend that teams can PR to over time. This is unideal, and also incomplete.
 *
 * Please see this issue on how we think we can improve this https://git.hubteam.com/HubSpot/crm-object-search-query-libs/issues/48
 *
 * @param objectTypeId The object type id to get required properties for. Accepts 2-* as a wildcard for all custom objects.
 */
export const getRequiredProperties = objectTypeId => {
  var _REQUIRED_PROPERTIES_, _PORTAL_SPECIFIC_REQU, _PORTAL_SPECIFIC_REQU2;
  const requiredPropertiesForObjectType = (_REQUIRED_PROPERTIES_ = REQUIRED_PROPERTIES_BY_OBJECT_TYPE[objectTypeId]) !== null && _REQUIRED_PROPERTIES_ !== void 0 ? _REQUIRED_PROPERTIES_ : [];
  const requiredProperties = new Set([...REQUIRED_PROPERTIES_FOR_ALL_OBJECT_TYPES, ...requiredPropertiesForObjectType,
  // dynamic call for test harness.
  ...((_PORTAL_SPECIFIC_REQU = (_PORTAL_SPECIFIC_REQU2 = PORTAL_SPECIFIC_REQUIRED_PROPERTIES[PortalIdParser.get()]) === null || _PORTAL_SPECIFIC_REQU2 === void 0 ? void 0 : _PORTAL_SPECIFIC_REQU2[objectTypeId]) !== null && _PORTAL_SPECIFIC_REQU !== void 0 ? _PORTAL_SPECIFIC_REQU : [])]);
  return Array.from(requiredProperties).sort();
};